<template>
  <div class="company-filter-wrapper">
    <div class="company-filter-card">
      <div class="company-filter-item top">
        <div class="company-filter-item-left">
          <div :class="'filter-company-cate ' + (curIndex === i ? 'active' : '')" v-for="(l, i) in selectKeys" :key="i"
            @click="toggleCate(l.value)" v-text="l.label" />
        </div>
        <div class="search-box">
          <el-input style="width: 400px;" placeholder="请输入内容" class="search-input" prefix-icon="el-icon-search" @change="searched"
            v-model="search_content" size="default">
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { companyCate } from "constants/publicData";
export default {
  name: "SexIndustryCompanyFilter",
  props: {
    initData: Object,
  },
  data() {
    return {
      curIndex: 1,
      search_content: "",
      selectItems: {},
      recent: null,
      back: {},

    };
  },
  created() {
    if (this.initData) {
      this.init(this.initData);
    }
  },
  methods: {
    init(initData) {
      const { search_content } = initData;

      companyCate.map((item) => {
        if (initData[item.value]) {
          this.selectItems[item.value] = initData[item.value];
        }
      });

      if (search_content) {
        this.search_content = search_content || "";
      }
    },
    toggleCate(v) {
      console.log(v);
      this.$emit("tabChange", v);
    },
    searched(val) {
      this.search_content = val;
      this.callBack();
    },
    callBack() {
      let data = {
        search_content: this.search_content,
        ...this.selectItems
      };
      console.log(data);
      this.$emit("change", data);
    },
  },
  computed: {
    selectKeys: () => [
      {
        label: "职位", value: "job"
      },
      {
        label: "企业", value: "company"
      }
    ],
  },
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";

.company-filter-card {
  background-color: $defaultBg;
  margin-top: 26px;
  padding: 15px 10px;
  box-shadow: 0 0 18px $shadowColor;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.company-filter-item {
  padding: 20px 13px 0;
  display: flex;
  justify-content: space-between;

  &.top {
    padding: 0;
  }

  .company-filter-item-left {
    display: flex;

    .dropdown-box {
      margin-right: 140px;

      .drop-title {
        color: $moreColor;
        margin-right: 20px;
      }

      .el-dropdown-link {
        cursor: pointer;
      }
    }

    .filter-company-cate {
      position: relative;
      cursor: pointer;
      margin: 0 13px;
      padding: 7px 23px;

      &.active {
        &::after {
          width: 100%;
          height: 2px;
          content: "";
          position: relative;
          top: 12px;
          background-color: var(--moreColor, #666);
          display: block;
        }
      }
    }
  }

  .search-input {
    .el-input__inner {
      border-radius: $borderRound;

      &:focus {
        border-color: $moreColor;
      }

      // background-color: $searchBg;
    }
  }

  .filter-clear {
    cursor: pointer;
    font-size: $medium;
    color: $moreColor;
  }
}
</style>
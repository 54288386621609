<template>
  <div class="six-industry-page page-container">
    <BannerSwiper height="220px" :swiperList="bannerList" />
    <div class="six-industry">
      <div v-for="(item) in sixIndustry" :key="item.title" class="six-industry-item">
        <div class="content" :class="keyIndustry === item.value ? 'active' : ''" @click="selectSexIndustry(item.value)">
          <div class="title">{{ item.title }}</div>
          <div class="count">公司 <span class="num"> {{ item.companyCount }} </span>家</div>
          <div class="count">职位 <span class="num"> {{ item.jobCount }} </span>个</div>
        </div>
        <div class="item-line"></div>
      </div>
    </div>

    <div v-if="activityKey === 'job'">
      <JobFilter :initData="jobFilterData" @change="getJobFilterData" @tabChange="onTabChange" />
      <JobListWrapper ajaxApi="getSixIndustryJobs" cardVersion="new" :nums="18" pagination :filterData="jobFilterData"
        sixIndustry style="margin-top:26px" />
    </div>
    <div v-if="activityKey === 'company'">
      <CompanyFilter :initData="companyFilterData" @change="getCompanyFilterData" @tabChange="onTabChange" />
      <CompanyListWrapper ajaxApi="getSixIndustryCompanies" :nums="15" pagination :filterData="companyFilterData"
        style="margin-top:26px" sixIndustry />
    </div>

  </div>
</template>

<script>
import BannerSwiper from "components/bannerSwiper";
import JobFilter from "../../components/sixIndustry/jobFilter.vue";
import CompanyFilter from "../../components/sixIndustry/companyFilter.vue";
import CompanyListWrapper from "components/company/companyListWrapper"
import JobListWrapper from "components/jobs/jobListWrapper"

export default {
  name: "sixIndustry",
  data: () => ({
    bannerList: [{
      img_url: 'https://qz-1257093263.cos.ap-shanghai.myqcloud.com/qzrcw/qz-six-industry-banner.jpg',
      title: "",
      link: ""
    }],
    activityKey: 'job',
    keyIndustry: '1',
    sixIndustry: [
      {
        title: '新材料',
        value: '1',
        companyCount: 37,
        jobCount: 285
      },
      {
        title: '集成电路',
        value: '2-',
        companyCount: 8,
        jobCount: 109
      },
      {
        title: '新能源',
        value: '3-',
        companyCount: 7,
        jobCount: 61
      },
      {
        title: '智能装备',
        value: '4-',
        companyCount: 24,
        jobCount: 144
      },
      {
        title: '特种纸',
        value: '5-',
        companyCount: 23,
        jobCount: 118
      },
      {
        title: '生命健康',
        value: '6-',
        companyCount: 20,
        jobCount: 147
      }

    ],
    jobFilterData: {
      search_content: "",
      key_industry: '1',
    },
    companyFilterData: {
      search_content: "",
      key_industry: '1',
    }
  }),
  created() {
    let jobFilterData = { ...this.jobFilterData }
    jobFilterData.search_content = this.$route.params.search_content || ""
    this.jobFilterData = jobFilterData

    let companyFilterData = { ...this.companyFilterData }
    companyFilterData.search_content = this.$route.params.search_content || ""
    this.companyFilterData = companyFilterData
  },
  methods: {
    getJobFilterData(data) {
      this.jobFilterData = data
    },
    getCompanyFilterData(data) {
      this.companyFilterData = data
    },
    selectSexIndustry(value) {
      this.keyIndustry = value;

      let jobFilterData = { ...this.jobFilterData }
      jobFilterData.key_industry = value;
      this.jobFilterData = jobFilterData;

      let companyFilterData = { ...this.companyFilterData }
      companyFilterData.key_industry = value;
      this.companyFilterData = companyFilterData
    },
    onTabChange(key) {
      this.activityKey = key
    }
  },
  computed: {

  },
  watch: {},
  components: { BannerSwiper, CompanyListWrapper, JobListWrapper, JobFilter, CompanyFilter },
}
</script>

<style lang="scss">
@import 'constants/style/public';

.six-industry {
  margin-top: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;



  .six-industry-item {
    flex: 1;
    display: flex;
    align-items: center;
    height: 150px;
    cursor: pointer;

    .content {
      flex: 1;
      padding: 16px 24px;

      .title {
        font-size: 20px;
        font-weight: bolder;
        margin-bottom: 16px;
      }

      .count {
        font-size: 14px;
        color: #999;
        margin-bottom: 8px;
        .num{
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }
      }

      &:hover,
      &.active {
        .title {
          color: $primaryColor;
        }
      }
    }

    .item-line {
      background-color: #efefef;
      height: 75%;
      width: 1px;
      flex: none;
    }



    &:last-of-type {
      .line {
        display: none;
      }
    }
  }
}
</style>